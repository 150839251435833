<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-12">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <div class="row">
                          <div class="col-4">
                            <h1 style="font-size: 48px">POWERFLOW PRO</h1>
                          </div>
                        </div>
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Wave_Soldering/4csm_Ersa-Wave-POWERFLOWPRO-01-900x675_b156afbd8a.png"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Wave_Soldering/14csm_Kurtz-Ersa-Technology-Wavesoldering-Header-900x675_7b8fce4c01.png"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <br /><br />
                <div class="mt-4">
                  <h1>Tech-Highlights PRO</h1>
                  <br />
                  <ul class="container">
                    <li>
                      <h3>
                        Compact full tunnel wave soldering system for
                        cost-optimized wave soldering
                      </h3>
                    </li>
                    <br />
                    <li>
                      <h3>
                        Modular, flexible and individually expandable preheating
                        concept variably configurable in length and power
                      </h3>
                    </li>
                    <br />
                    <li>
                      <h3>
                        Award-winning database-based operating software <br />
                        ERSASOFT 5 > Ready for Industry 4.0
                      </h3>
                    </li>
                    <br />
                    <li>
                      <h3>
                        Finger and frame transport with different working widths
                      </h3>
                    </li>
                    <br />
                  </ul>
                </div>
              </div>
            </div>
            <!-- end row -->

            <br /><br /><br /><br /><br /><br /><br /><br />

            <div class="container">
              <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="2000"
                controls
                indicators
                background="#fff"
                style="text-shadow: 1px 1px 2px #333"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <div class="carousel-item" active>
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Wave_Soldering/41csm_Ersa-Wave-POWERFLOWPRO-1140370-1005x600_3baf7837bd.jpg"
                      alt="category.name"
                    />
                  </div>
                  <br />
                  <div class="parent d-flex justify-content-center">
                    <p class="font-size-10">
                      POWERFLOW PRO: Intelligent nitrogen control guarantees
                      optimized N2 consumption and cost efficiency
                    </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Wave_Soldering/42csm_Ersa-Wave-01-900x600_a7372baadd.jpg"
                      alt="category.name"
                    />
                  </div>
                  <br />
                  <div class="parent d-flex justify-content-center">
                    <p class="font-size-10">
                      POWERFLOW PRO: Maximum flexibility - the right solution
                      for every requirement
                    </p>
                  </div>
                </div>

                <div class="carousel-item">
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Wave_Soldering/43csm_Ersa-Wave-POWERFLOW-01-900x600_97e9351f72.jpg"
                      alt="category.name"
                    />
                  </div>
                  <br />
                  <div class="parent d-flex justify-content-center">
                    <p class="font-size-10">
                      POWERFLOW PRO: Efficient, consistent, reproducible
                    </p>
                  </div>
                </div>
              </b-carousel>
              <br /><br />
            </div>

            <br /><br /><br /><br /><br /><br /><br /><br />

            <div class="row">
              <div class="col-8" style="margin-left: 50px">
                <h1><strong>Technical data</strong></h1>
              </div>
              <div class="col-3"></div>
            </div>
            <br />
            <b-tabs pills card vertical style="font-size: 18px">
              <b-tab active title="Dimensions">
                <div class="row" style="margin-bottom: 5px; margin-left: 10%">
                  <div class="col">
                    <h4><strong>Length : </strong> 4,450 mm</h4>
                  </div>
                  <div class="col">
                    <h4><strong>Height :</strong> approx. 1,600 mm</h4>
                  </div>
                </div>
                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4><strong>Width : </strong> approx. 1,400 mm</h4>
                  </div>
                  <div class="col"></div>
                </div>
              </b-tab>

              <b-tab title="Conveyor systems">
                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4>Finger conveyor, Pallet conveyor</h4>
                  </div>
                  <div class="col">
                    <h4>Maximum working width up to 458 mm</h4>
                  </div>
                </div>
                <div class="row" style="margin-left: 10%">
                  <div class="col"><h4>Maximum conveyor speed 3 m/min</h4></div>
                  <div class="col">
                    <h4>
                      Maximum height of components, top of board: up to 100 mm
                    </h4>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Preheating / Solder Module">
                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4>
                      <strong> Preheating : </strong> Heat transfer by
                      convection, medium wave radiation and short wave radiation
                    </h4>
                  </div>
                  <div class="col">
                    <h4>
                      <strong> Individually configurable</strong> up to a
                      preheat length of 2,000 mm
                    </h4>
                  </div>
                </div>

                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4></h4>
                  </div>
                  <div class="col">
                    <h4>
                      <strong>Soldering module : </strong> depending on
                      configuration 525 to 725 kg Solder content
                    </h4>
                  </div>
                </div>
              </b-tab>
            </b-tabs>

            <br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-12">
                <div class="">
                  <div class="">
                    <div class="row">
                      <div class="col-xl-7">
                        <!-- 16:9 aspect ratio -->
                        <div class="ratio ratio-21x9">
                          <iframe
                            style="width: 600px; height: 300px"
                            title="YouToube Video"
                            src="https://www.youtube.com/embed/T-PUQ1wycPs"
                            allowfullscreen=""
                          ></iframe>
                        </div>
                      </div>
                      <div class="col-xl-5">
                        <br /><br />
                        <h1>Ersa POWERFLOW PRO:</h1>
                        <h4>
                          Compact full tunnel wave soldering system for
                          cost-optimized wave soldering in a nitrogen atmosphere
                          with award-winning ERSASOFT 5.
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>